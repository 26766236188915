@import '@/_vars.scss';

html {
	font-size: 16px; // 1rem
	height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: $black;
  background-color: $white;
  font-family: $font-stack;
}
.center-loader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
input {
	cursor: text;
}
::placeholder {
  font-weight: 200;
}
h1, h2, h3, h4, h5, h6, p, span, a {
  line-height: 140%;
  font-family: $font-stack;
  text-decoration: none;
  word-wrap: break-word;
}
.comment-markup {
	max-width: 100%;
	font-size: 1rem;
	word-wrap: break-word;
	p {
		font-family: $font-stack;
	  white-space: pre-line;
	}
	ol {
		font-family: $font-stack;
	  margin: 0.25rem 0;
	}
	li {
		font-family: $font-stack;
	  margin: 0.25rem 0;
	}
	ul {
		font-family: $font-stack;
	  margin: 0.25rem 0;
	  margin-block-start: 0;
	  margin-block-end: 0;
	}
	h1 {
		font-family: $font-stack;
	  font-size: 1.9rem;
	}
	h2 {
		font-family: $font-stack;
	  font-size: 1.2rem;
	}
	a {
		font-family: $font-stack;
	  color: $primary-color;
	  text-decoration: none;
	}
	em {
		font-family: $font-stack;
	}
	img {
		width: 100%;
	}
	// Blockquote CSS
	blockquote {
	  border-left: 3px solid $primary-color;
	  font-family: $font-stack;
	  font-style: italic;
	  margin: 1rem 2rem;
	  padding: 0.5rem 2rem;
	  position: relative;
	  transition: .2s border ease-in-out;
	  &:before {
	    content: "";
	    position: absolute;
	    top: 50%;
	    left: -4px;
	    height: 2rem;
	    background-color: $white;
	    width: 5px;
	    margin-top: -1rem;
	  }
	  &:after {
	    content: "\f35f";
	    position: absolute;
	    top: 50%;
	    left: -0.5rem;
	    color: $primary-color;
	    font-family: "Ionicons";
	    font-style: normal;
	    line-height: 1rem;
	    text-align: center;
	    text-indent: -2px;
	    width: 1rem;
	    margin-top: -0.5rem;
	    transition: .2s all ease-in-out, .4s transform ease-in-out;
    }
  }
}

/* Containers */
.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.container {
	display: flex;
	width: 100%;
}
.focus-container {
	width: 100%;
	max-width: 61.25rem;
	margin: 0 auto;
	.focus-title-section {
		padding: 0;
		margin: 7rem auto;
		overflow: visible;
	}
	.focus-subheader {
		color: $grey;
		margin: 0 auto 1rem;
		font-size: 1.5rem;
	}
	.focus-title {
		margin: 0 auto;
		line-height: 1.25;
		font-weight: 700;
		color: $black;
		font-size: 3.5rem;
	}
}
.column {
	display: flex;
	flex-direction: column;
}
.row {
	display: flex;
	flex-direction: row;
}
.mobile-only {
	display: none;
}
.legal {
	display: flex;
	flex-direction: column;
	padding: 2rem;
	max-width: 60rem;
	margin: 0 auto;
	h1, h2 {
		margin: 2rem 0 0;
	}
	p, em {
		margin: 1rem 0 0;
	}
}

/* Colors */
.color-primary {
	color: $primary-color;
}
.color-secondary {
	color: $secondary-color;
}
.color-grey {
	color: $grey;
}
.color-light-grey {
	color: $light-grey;
}
.color-white {
	color: $white;
}
.color-black {
	color: $black;
}
.color-error {
	color: $error;
}

/* Buttons, inputs, etc. */
button, input, textarea {
	font-family: $font-stack;
	width: 19rem;
	box-shadow: $box-shadow;
	font-size: 1rem;
	padding: 0.5rem 1rem;
  border: none;
  align-items: center;
  transition: all 150ms ease-in-out;
  border-radius: 0.2rem;
  resize: none;
}
button {
	width: 21rem;
	cursor: pointer;
	outline: none;
	color: $white;
	background-color: $primary-color;
	&:hover {
		background-color: $primary-shadow;
	}
}
.secondary-button {
	color: $dark-grey;
	background-color: $secondary-color;
	&:hover {
		background-color: $secondary-shadow;
	}
}
.disabled-button {
	opacity: 0.5;
	pointer-events: none;
}
.input {
	box-shadow: $box-shadow;
}
.input-title {
	font-size: 0.9rem;
	color: $grey;
	margin-bottom: 0.25rem;
}
.auth-input {
	background-color: $off-white;
}

/* Text */
.serif {
	font-family: serif;
}
.xsmall {
	font-size: 0.75rem;
}
.small {
	font-size: 0.9rem;
}
.medium {
	font-size: 1rem;
}
.large {
	font-size: 1.5rem;
}
.xlarge {
	font-size: 2rem;
}
.xxlarge {
	font-size: 3rem;
}
.underline {
	text-decoration: underline;
}
.hover {
	cursor: pointer;
}
.thin {
	font-weight: 200;
}
.bold {
	font-weight: 700;
}
.auth-title {
	font-size: 3.3rem;
	margin: 7rem auto 1.5rem;
	font-weight: 700;
}
.error-text {
	font-size: 0.9rem;
	color: $error;
	margin-bottom: 0.25rem;
}
.warning-text {
	font-size: 0.9rem;
	color: $yellow-medium;
	margin-top: 0.5rem;
}
.focusable {
	max-width: 21rem;
}
.strikethrough {
	text-decoration: line-through;
}

/* Sizing */
.full-width {
	width: 100%;
}
.margin-top {
	margin-top: 1rem
}
.margin-right {
	margin-right: 1rem
}
.margin-bottom {
	margin-bottom: 1rem
}
.margin-left {
	margin-left: 1rem
}
.margin {
	margin: 1rem;
}
.no-margin {
	margin: 0;
}
.padding-top {
	padding-top: 1rem;
}
.padding-right {
	padding-right: 1rem;
}
.padding-bottom {
	padding-bottom: 1rem;
}
.padding-left {
	padding-left: 1rem;
}
.padding {
	padding: 1rem;
}
.no-padding {
	padding: 0;
}

/* Alignment */
.align-content-center {
	align-content: center;
}
.align-items-center {
	align-items: center;
}
.align-self-center {
	align-self: center;
}
.justify-content-center {
	justify-content: center;
}
.text-align-center {
	text-align: center;
}
.text-align-left {
	text-align: left;
}

/* Images */
.error-image {
	width: 25%;
	margin: 4rem;
	opacity: 70%;
}

/* MOBILE */
@media screen and (max-width: 980px) {
	.auth-title {
		padding: 0 2rem;
	}
	.mobile-hidden {
		display: none;
	}
	.mobile-only {
		display: flex;
		position: relative;
	}
	.mobile-padding {
		padding: 0 2rem;
	}
	.focus-container {
		.focus-title-section {
			margin: 2rem 1rem;
		}
		.focus-subheader {
			color: $black;
			font-size: 2.5rem;
		}
		.focus-title {
			display: none;
		}
	}
	.focusable {
		width: 100%;
		max-width: 100%;
	}
	input {
		width: calc(100% - 2rem);
		max-width: calc(100% - 2rem);
	}
	button {
		max-width: 100%;
		width: 100%;
	}
}