$primary-color: #4254FF;
$primary-shadow: #3545EE;
$primary-light: #ECEDFF;
$secondary-color: #FAE1DA;
$secondary-shadow: #EECEBD;
$free-pill: #DCF9F4;
$selected-comment: #FFFFDD;
$grey: #8E8D89;
$light-grey: #EEE;
$dark-grey: #616161;
$white: #FFFEFC;
$off-white: #F7F7F4;
$green-easy: #1DAF97;
$yellow-medium: #EDCD60;
$red-hard: #DC3734;
$black: #222;
$error: #CE544B;
$font-stack: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
$serif-stack: fell, Georgia, Cambria, Times New Roman, Times, serif;
$box-shadow: rgba(15,15,15,0.1) 0px 0px 0px 1px inset, rgba(15,15,15,0.1) 0px 0px 1px inset;