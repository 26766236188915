@import '@/_vars.scss';

.unsubscribe {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.unsubscribe-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1.5rem;
}
.unsubscribe-options {
	display: flex;
	flex-direction: column;
	text-align: center;
	.leave-button {
		margin: 0.5rem 0;
		height: 3rem;
		background-color: $secondary-color;
		color: $black;
		&:hover {
			background-color: $secondary-shadow;
		}
	}
	.stay-button {
		margin: 0.5rem 0;
		height: 3rem;
	}
}